import * as React from "react";

export interface IBaseMenuPreInitState {
    preInitCssFix: boolean
    menuOpened: boolean
}

export interface IMenuPreInit {
    cssClass: string
    onReady(): void
}

export function menuPreInitPatch(component: React.Component<{},IBaseMenuPreInitState>): IMenuPreInit {
    const menuOpened = component.state.menuOpened;
    component.setState({ preInitCssFix: true });
    return {
        get cssClass() {
            if (menuOpened) {
                return "";
            }

            return (component.state.preInitCssFix ? " pre-init-blink-fix" : "");
        },

        onReady() {
            if (menuOpened) {
                return;
            }

            setTimeout(() => {
                component.setState({ preInitCssFix: false });
            });
        }
    };
}
