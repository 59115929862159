import React from 'react';
import './home.scss';

export default () => (
  <React.Fragment>
    <h2 className={'content-block'}>Home</h2>
    <div className={'content-block'}>
      <div className={'dx-card responsive-paddings'}>
        <div className={'logos-container'}>

        </div>
          <p>Coming soon...</p>
        </div>
    </div>
  </React.Fragment>
);
