import AuthenticationService from "./authenticationService";

class ApiServerRequest {
	private _serverAddr: string;

	constructor(serverAddr:string = '/api') {
		//serverAddr = 'http://localhost:5000/api';
		serverAddr = 'https://dev.rapempire.icvr.io:9091/api';
		this._serverAddr = serverAddr;
	}

	getRequest<T>(action:string): Promise<T> {
		console.log('getRequest', action);

		let args = { method: "get", headers: {}} ;
		if (AuthenticationService.getAuthToken().length > 0) {
			args.headers = {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + AuthenticationService.getAuthToken()
			}
		}
		else {
			args.headers = {
				'Content-Type': 'application/json',
			}
		}

		console.log(args)
		return new Promise((resolve) => {
			let actualResponse: Response;
			fetch(this._serverAddr + action, args)
				.then(response => {
					actualResponse = response;
					return response.json()
				})
				.then(body => {
					if (actualResponse.ok) {
						return resolve(body);
					}
					throw Error(body);
				});
		});
	}

	postRequestBlob<T>(action:string, params:any): Promise<Blob> {
		return new Promise((resolve) => {
			fetch(this._serverAddr + action, {
				method: "post",
				headers : {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + AuthenticationService.getAuthToken()
				},
				body: JSON.stringify(params)
			})
				.then(response => response.blob())
				.then(body => resolve(body));
		});
	}

	postTextRequest(action:string, params:any): Promise<string> {
		console.log('postRequest', action, AuthenticationService.getAuthToken());
		let args = { method: "post", body: JSON.stringify(params), headers: {}} ;
		if (AuthenticationService.getAuthToken().length > 0) {
			args.headers = {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + AuthenticationService.getAuthToken()
			}
		}
		else {
			args.headers = {
				'Content-Type': 'application/json',
			}
		}

		return new Promise((resolve) => {
			let actualResponse: Response;
			fetch(this._serverAddr + action, args)
				.then(response => {
					actualResponse = response;
					return response.text()
				})
				.then(body => {
					if (actualResponse.ok) {
						return resolve(body);
					}

					console.log("API ERROR", actualResponse.status, body);
					throw Error(body);
				});
		});
	}

	postRequest<T>(action:string, params:any): Promise<T> {
		//console.log('postRequest', action, AuthenticationService.getAuthToken());
		let args = { method: "post", body: JSON.stringify(params), headers: {}} ;
		if (AuthenticationService.getAuthToken().length > 0) {
			args.headers = {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + AuthenticationService.getAuthToken()
			}
		}
		else {
			args.headers = {
				'Content-Type': 'application/json',
			}
		}

		return new Promise((resolve) => {
			let actualResponse: Response;
			fetch(this._serverAddr + action, args)
				.then(response => {
					actualResponse = response;
					return response.json()
				})
				.then(body => {
					if (actualResponse.ok) {
						return resolve(body);
					}

					console.log("API ERROR", actualResponse.status, body);
					throw Error(body);
				});
		});
	}
}

export default new ApiServerRequest()