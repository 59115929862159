export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'GameData',
    icon: 'folder',
    items: [
      {
        text: 'Import',
        path: '/import_gd'
      },
    ]
  },
  {
    text: 'Analytics',
    icon: 'folder',
    items: [
      { text: 'Events', path: '/analytics' },
    ]
  }
];
