import React from 'react';
import ContextMenu from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import './user-panel.scss';
import {UserProfile} from "../../services/authenticationService";

export enum UserPanelMenuMode {
  Context,
  List
}

interface IUserPanelProps {
  userProfile: UserProfile;
  menuMode: UserPanelMenuMode;
  menuItems: any;
}

export default class UserPanel extends React.Component<IUserPanelProps,{}> {
  constructor(props: IUserPanelProps) {
    super(props);
    this.state = {};
  }

  render() {
    let menuItems = this.props.menuItems;
    let menuMode = this.props.menuMode;
    let userName = this.props.userProfile.userName;

    return (
        <div className={'user-panel'}>
          <div className={'user-info'}>
            <div className={'image-container'}>
              <div className={'user-image'}/>
            </div>
            <div className={'user-name'}>{userName}</div>
          </div>

          {menuMode === UserPanelMenuMode.Context && (
              <ContextMenu
                  items={menuItems}
                  target={'.user-button'}
                  showEvent={'dxclick'}
                  width={170}
                  position={{
                    my: {x: 'center', y: 'top'},
                    at: {x: 'center', y: 'top'}
                  }} //{ { my: 'top center', at: 'bottom center'} }
                  cssClass={'user-menu'}
              />
          )}
          {menuMode === UserPanelMenuMode.List && (
              <List className={'dx-toolbar-menu-action'} items={menuItems}/>
          )}
        </div>
    );
  }
}
