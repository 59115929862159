import ApiServerRequest from "../api_requests";

export interface IClusterData {
    id: string
    name: string
}

class ApiClusterService {
    async getClusters(): Promise<IClusterData[]> {
        return ApiServerRequest.getRequest<IClusterData[]>("/Cluster/Clusters");
    }

}

export default new ApiClusterService()