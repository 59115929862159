import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.material.blue.light.css';

import React, { Component } from 'react';
import { HashRouter as Router, Route} from 'react-router-dom';
import './App.scss';
import './dx-styles.scss';
import { LoginForm } from './components';
import { SingleCard } from './layouts';

import { sizes, subscribe, unsubscribe } from './utils/media-query';
import AuthenticationService from "./services/authenticationService";
import notify from "devextreme/ui/notify";
import {WorkplaceLayout} from './layouts/workplace/workplaceLayout'

interface AppState {
  isAuthenticated: boolean;
  screenSizeClass: string;
}

interface AppMenu {
  text: string;
  icon: string;
  onClick: () => void;
}

class App extends Component<{},AppState> {
  private readonly _userMenuItems: AppMenu[];

  constructor(props: any) {
    super(props);

    this.state = {
      isAuthenticated: AuthenticationService.isAuthenticated(),
      screenSizeClass: this.getScreenSizeClass()
    };

    this._userMenuItems = [
      {
        text: 'Profile',
        icon: 'user',
        onClick: () => {}
      },
      {
        text: 'Logout',
        icon: 'runner',
        onClick: this.logOut
      }
    ];
  }

  getScreenSizeClass(): string {
    const screenSizes = sizes();
    return Object.keys(screenSizes).filter(cl => screenSizes[cl]).join(' ');
  }

  screenSizeChanged = () => {
    this.setState({
      screenSizeClass: this.getScreenSizeClass()
    });
  };

  logIn = async (login:string, password:string, clusterId:string) => {

    try {
      await AuthenticationService.login(login, password, clusterId);
      this.setState({isAuthenticated: true});
    }
    catch (e) {
      notify('Login/Password incorrect or not found', 'error', 600);
    }
  };

  logOut = () => {
    AuthenticationService.setToken('');
    this.setState({isAuthenticated: false});
  };

  componentDidMount() {
    subscribe(this.screenSizeChanged);
  }

  componentWillUnmount() {
    unsubscribe(this.screenSizeChanged);
  }

  componentWillMount() {
    this.setState( { isAuthenticated: AuthenticationService.isAuthenticated() });
  }

  render() {
    let loggedIn = this.state.isAuthenticated;
    return (
        <div className={`app ${this.state.screenSizeClass}`}>
          <Router>{
            loggedIn
                ? <WorkplaceLayout userMenuItems={this._userMenuItems} userProfile={AuthenticationService.getProfile()}/>
                : <SingleCard> <Route render={() => <LoginForm onLoginClick={this.logIn}/>}/> </SingleCard>}
          </Router>
        </div>
    );
  }
}

export default App;
