import React from "react";
import {Button, DataGrid} from "devextreme-react";
import notify from 'devextreme/ui/notify';
import './import_gd.scss'
import {Column, Pager, Paging} from "devextreme-react/data-grid";
import GamedataService, {IGameData} from "../../../services/gamedataService";

interface IExportGDPageState {
	requestText: string
	exportList: IGameData[]
	disableButtons: boolean
}

export class ImportGameDataPage extends React.Component<{}, IExportGDPageState> {
	constructor(props:any) {
		super(props);

		this.state = {
			requestText: "",
			exportList: [],
			disableButtons: false
		};

		this.onReloadData();
	}

	onReloadData = async () => {
		this.setState({disableButtons: true});
		let r = await GamedataService.getDocuments();
		this.setState({
			exportList: r,
			disableButtons: false
		});
	};

	onExportData = async () => {
		this.setState({disableButtons: true});
		try {
			await GamedataService.startImportGamedata();
		}
		catch (e) {
			notify(e, 'error');
			console.log(e);
		}
		this.setState({disableButtons: false});
	};

	onSetDefault = async (id:IGameData) => {
		try {
			await GamedataService.setDefaultGamedata(id.version);
			this.onReloadData();
		}
		catch (e) {
			notify(e, 'error');
		}
	};

	onDownloadGD = async (id:IGameData) => {
		let value = await GamedataService.downloadDocument(id.version);
		let filename = 'gamedata_' + value.version + '.json';
		let contentType = 'application/json;charset=utf-8;';
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			let blob = new Blob([decodeURIComponent(encodeURI(value.document))], { type: contentType });
			navigator.msSaveOrOpenBlob(blob, filename);
		} else {
			let a = document.createElement('a');
			a.download = filename;
			a.href = 'data:' + contentType + ',' + encodeURIComponent(value.document);
			a.target = '_blank';
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		}
	};

	render() {
		if (this.state.exportList.length === 0) {
			return (
				<React.Fragment>
					<h2 className={'content-block'}>Manage gamedata</h2>
					<div className={'content-block dx-card responsive-paddings'}>
						<p> Error data loading </p>
					</div>
				</React.Fragment>);
		}
		else
			return (
				<React.Fragment>
					<h2 className={'content-block'}>Manage gamedata</h2>
					<div className={'content-block dx-card responsive-paddings'}>
						<div className={'header'}>
							<div><Button key={'1'} text={'reload'} type={'normal'} disabled={this.state.disableButtons} onClick={this.onReloadData} /></div>
							<div><Button key={'2'} text={'import now'} type={'normal'} disabled={this.state.disableButtons} onClick={this.onExportData} /></div>
						</div>
						<DataGrid
							dataSource={this.state.exportList}
							showBorders={true}>
							<Column dataField={'id'} />
							<Column
								dataField={'created'}
								sortOrder={'desc'}
							/>
							<Column dataField={'version'} />
							<Column dataField={'hash'} />
							<Column dataField={'activeProfiles'} />
							<Column
								dataField={'download'}
								width={300}
								cellRender={(ctx) =>
									<div className={'header'}>
										<div>
											<Button text={'Download'} onClick={() => this.onDownloadGD(ctx.data)}/>
										</div>
										<div></div>
										{ !ctx.data.isDefault ? <div>
											<Button text={'Set default'} onClick={() => this.onSetDefault(ctx.data)}/>
										</div> : null }
									</div>
								}
							/>
							<Paging defaultPageSize={20} />
							<Pager
								showPageSizeSelector={true}
								allowedPageSizes={[8, 12, 20]}
							/>
						</DataGrid>
					</div>
				</React.Fragment>
			);
	};
};
