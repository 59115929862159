import ApiServerRequest from "./api_requests";
let jwt = require('jwt-decode');

export class JwtToken {
	token: string = '';
}

export class UserProfile {
	aud: string = '';
	exp: number = 0;
	iss: string = '';
	loginId: string = '';
	userName: string = '';
	profileName: string = '';
	profileId: string = '';
}

class AuthenticationService {
	private _profile: UserProfile | undefined;
	private _token: string;
	private static authTokenName = "sessionToken";

	constructor() {
		this._profile = undefined;
		this._token = "" + localStorage.getItem(AuthenticationService.authTokenName);
		this.loadProfileToken();
	}

	async login(email:string, passwd:string, clusterId:string): Promise<void> {
		let result = await ApiServerRequest.postRequest<string>("/Login/SignIn", {"login": email, "password": passwd, "clusterId": clusterId});
		this.setToken(result);
	}

	getProfile(): UserProfile {
		if (this._profile) {
			return this._profile;
		} else {
			throw new Error('Profile not load!');
		}
	}

	setToken(token:string) {
		console.log('setToken', token);

		this._profile = new UserProfile();
		this._token = token;
		localStorage.setItem(AuthenticationService.authTokenName,token);
		this.loadProfileToken();
	}

	loadProfileToken(): void {
		if (!this._token || this._token === 'undefined' || this._token.length === 0) {
			this._profile = undefined;
			return;
		}

		try {
			console.log('loadProfileToken', this._token, this._token.length);
			let decodedToken = jwt(this._token);
			console.log("decodedToken:", decodedToken);
			let nowDate = Math.round(new Date().getTime() / 1000);
			if (decodedToken.exp >= nowDate) {
				this._profile = decodedToken;
			} else {
				this._profile = undefined;
			}
		}
		catch (e) {
			this._profile = undefined;
			return;
		}
	}

	isAuthenticated(): boolean {
		if (this._token.length === 0 || this._profile === undefined) {
			return false;
		}

		try {
			let nowDate = Math.round(new Date().getTime()/1000);
			if (this._profile.exp >= nowDate) {
				return true;
			}

			this.setToken("");
			return false;
		}
		catch (e) {
			this.setToken("");
			return false;
		}
	}

	getAuthToken(): string {
		return this._token;
	}
}

export default new AuthenticationService()