import { HomePage, DisplayDataPage} from './pages';
import {ImportGameDataPage} from "./pages/gamedata/import/import_gd";
import {EventsServerPage} from "./pages/events/events";

export default [
  {
    path: '/display-data',
    component: DisplayDataPage
  },
  {
    path: '/import_gd',
    component: ImportGameDataPage
  },
  {
    path: '/analytics',
    component: EventsServerPage
  },
  {
    path: '/home',
    component: HomePage
  }
  ];
