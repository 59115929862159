import ApiServerRequest from "../api_requests";

export interface IReportTypeModel {
    id: string
    name: string
}

export interface IReportModel {
    id: string
    created: string
    started: string
    finished: string
    reportTypeId: string
    status: number
    totalCount: number
    totalSize: number
}

class AnalyticsService {
    async getAnalyticsReports(): Promise<IReportModel[]> {
        return ApiServerRequest.getRequest("/Analytics/Reports");
    }

    async getAnalyticsReportTypes(): Promise<string[]> {
        return ApiServerRequest.getRequest("/Analytics/ReportTypes");
    }

    async postCreateAnalyticsReport(startDate:Date, finishDate:Date, reportTypeId:string): Promise<string> {
        return ApiServerRequest.postTextRequest("/Analytics/CreateReport",
            { startDate: startDate, finishDate: finishDate, reportTypeId:reportTypeId });
    }
}

export default new AnalyticsService()