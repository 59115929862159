import React from "react";
import {DateBox, Button, DataGrid, SelectBox} from "devextreme-react";
import './events.scss'
import AnalyticsService, { IReportModel } from "../../services/analytics/analyticsService"
import {Column, Pager, Paging} from "devextreme-react/data-grid";

interface IMonitoringPageState {
	startDate: Date
	finishDate: Date
	selectedReport: string
	disableButtons: boolean
	reports: IReportModel[]
	reportTypes: string[]
}


export class EventsServerPage extends React.Component<{}, IMonitoringPageState> {
	constructor(props:any) {
		super(props);

		this.state = {
			startDate: new Date(),
			finishDate: new Date(),
			disableButtons: false,
			selectedReport: 'Retention',
			reports: [],
			reportTypes: []
		};

		this.onReloadData()
	}

	onReloadData = async () => {
		this.setState({disableButtons: true});
		let reports = await AnalyticsService.getAnalyticsReports();
		let reportTypes = await AnalyticsService.getAnalyticsReportTypes();
		this.setState({
			disableButtons: false,
			reports: reports,
			reportTypes: reportTypes
		});
	};

	render() {
		return (
			<React.Fragment>
				<h2 className={'content-block'}>Reports</h2>
				<div className={'content-block dx-card responsive-paddings'}>
					<div className={'header'}>
						<div><DateBox value={this.state.startDate}
									  type="date" width={"110px"}
									  onValueChanged={(e:any) => this.setState({startDate: e.value})}
						/></div>
						<div><DateBox value={this.state.finishDate}
									  type="date"
									  width={"110px"}
									  onValueChanged={(e:any) => this.setState({finishDate: e.value})}
						/></div>
						<div><SelectBox
								dataSource={this.state.reportTypes}
								defaultValue={this.state.reportTypes[0]}
								disabled={this.state.disableButtons}
								width={200}
								onValueChanged={e => this.setState({selectedReport: e.value })}/>
						</div>
						<div>
							<Button key={'1'}
									text={'Create'}
									disabled={this.state.disableButtons} type={'normal'}
									onClick={
										async () => {
											await AnalyticsService.postCreateAnalyticsReport(this.state.startDate, this.state.finishDate, this.state.selectedReport);
											await this.onReloadData();
										}
									}/>
						</div>
						<div>
							<Button key={'1'} text={'Refresh'}  disabled={this.state.disableButtons} type={'normal'}
									onClick={
										async () => {
											await this.onReloadData();
										}
									}/>
						</div>
					</div>
						<DataGrid
							dataSource={this.state.reports}
							showBorders={true}>
							{/*<Column dataField={'id'} />*/}
							<Column
								dataField={'created'}
								sortOrder={'desc'}
							/>
							<Column dataField={'started'} dataType="date" />
							<Column dataField={'finished'} dataType="date" />
							<Column dataField={'reportTypeId'} />
							<Column dataField={'totalCount'} />
							<Column dataField={'totalSize'} />
							<Column dataField={'status'} />
							<Column
								dataField={'id'}
								width={300}
								caption={'download'}
								cellRender={(ctx) => {
									if (ctx.data.status === '') {
										return (<p></p>);
									} else {
										return (
											<div className={'header'}>
												<div>
													<a href={'https://dev.rapempire.icvr.io:9091/api/Analytics/Report/' + ctx.data.id}
													   target="_blank"
													   rel="noopener noreferrer"
													>Download report</a>
												</div>
											</div>)
									}
								}
							}
							/>
							<Paging defaultPageSize={20} />
							<Pager
								showPageSizeSelector={true}
								allowedPageSizes={[8, 12, 20]}
							/>
						</DataGrid>

				</div>
			</React.Fragment>)
	}
}