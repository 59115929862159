import Drawer from 'devextreme-react/drawer';
import ScrollView from 'devextreme-react/scroll-view';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import React from 'react';
import {withRouter, RouteComponentProps } from 'react-router';
import { SideNavigationMenu, Footer } from '../../components';
import './side-nav-inner-toolbar.scss';
import { sizes, subscribe, unsubscribe } from '../../utils/media-query';
import { Template } from 'devextreme-react/core/template';
import {menuPreInitPatch, IMenuPreInit } from '../../utils/patches';
import HeaderPanel from "../../components/header/headerPanel";
//import {UserProfile} from "../../services/authenticationService";
import DevExpress from "devextreme/bundles/dx.all";

/*
interface ISideNavInnerToolbarProps {
  menuItems: string
  title: string
  userMenuItems: string
  userProfile: UserProfile
}
*/

interface ISideNavInnerToolbarState {
  menuMode: 'overlap' | 'shrink' | 'push'
  shaderEnabled: boolean
  menuRevealMode: 'slide' | 'expand'
  minMenuSize: number
  preInitCssFix: boolean
  menuOpened: boolean
  temporaryMenuOpened: boolean
}

class SideNavInnerToolbar extends React.Component</*ISideNavInnerToolbarProps &*/ RouteComponentProps<{}>,ISideNavInnerToolbarState> {
  private menuPatch: IMenuPreInit = { cssClass: "", onReady: () => {} };

  constructor(props: /*ISideNavInnerToolbarProps &*/ RouteComponentProps<{}>) {
    super(props);

    const isXSmall = sizes()['screen-x-small'];
    const isLarge = sizes()['screen-large'];

    this.state = {
      menuOpened: sizes()['screen-large'],
      temporaryMenuOpened: false,
      preInitCssFix: true,
      menuMode: isLarge ? 'shrink' : 'overlap',
      menuRevealMode: isXSmall ? 'slide' : 'expand',
      minMenuSize: isXSmall ? 0 : 60,
      shaderEnabled: !isLarge
    };

    this.menuPatch = menuPreInitPatch(this);
  }

  render() {
    const { menuItems, title, location, userMenuItems } = this.props as any;
    const {
      menuOpened,
      menuMode,
      shaderEnabled,
      menuRevealMode,
      minMenuSize
    } = this.state;

    return (
      <div className={'side-nav-inner-toolbar'}>
        <Drawer
          className={'drawer' + this.menuPatch.cssClass}
          position={'before'}
          closeOnOutsideClick={this.closeDrawer}
          openedStateMode={menuMode}
          revealMode={menuRevealMode}
          minSize={minMenuSize}
          maxSize={250}
          shading={shaderEnabled}
          opened={menuOpened}
          template={'menu'}
        >
          <div className={'container'}>
            <HeaderPanel
              userMenuItems={userMenuItems}
              menuToggleEnabled={minMenuSize === 0}
              toggleMenu={this.toggleMenu}
              userProfile={(this.props as any).userProfile}
              title={""}
            />

            <ScrollView className={'layout-body with-footer'}>
              <div className={'content'}>
                {React.Children.map(this.props.children, (item:any) => {
                  return item.type !== Footer && item;
                })}
              </div>
              <div className={'content-block'}>
                {React.Children.map(this.props.children, (item: any) => {
                  return item.type === Footer && item;
                })}
              </div>
            </ScrollView>
          </div>
          <Template name={'menu'}>
            <SideNavigationMenu
              items={menuItems}
              compactMode={!menuOpened}
              selectedItem={location.pathname}
              className={'dx-swatch-additional'}
              selectedItemChanged={this.navigationChanged}
              openMenu={this.navigationClick}
              onMenuReady={this.menuPatch.onReady}
            >
              <Toolbar id={'navigation-header'}>
                <Item
                  location={'before'}
                  cssClass={'menu-button'}
                  widget={'dxButton'}
                  visible={minMenuSize !== 0}
                  options={{
                    icon: 'menu',
                    stylingMode: 'text',
                    onClick: this.toggleMenu
                  }}
                />
                <Item location={'before'} cssClass={'header-title'} text={title} />
              </Toolbar>
            </SideNavigationMenu>
          </Template>
        </Drawer>
      </div>
    );
  }

  componentDidMount() {
    subscribe(this.updateDrawer);
  }

  componentWillUnmount() {
    unsubscribe(this.updateDrawer);
  }

  closeDrawer = () => {
    if (!this.state.shaderEnabled) {
      return false;
    }

    this.setState({ menuOpened: false });
    return true;
  };

  updateDrawer = () => {
    const isXSmall = sizes()['screen-x-small'];
    const isLarge = sizes()['screen-large'];

    this.setState({
      menuOpened: sizes()['screen-large'],
      menuMode: isLarge ? 'shrink' : 'overlap',
      menuRevealMode: isXSmall ? 'slide' : 'expand',
      minMenuSize: isXSmall ? 0 : 60,
      shaderEnabled: !isLarge
    });
  };

  toggleMenu = (event:DevExpress.dxEvent) => {
    this.setState(({ menuOpened }) => {
      return { menuOpened: !menuOpened };
    });
    event.stopPropagation();
  };

  get hideMenuAfterNavigation() {
    const { menuMode, temporaryMenuOpened } = this.state;
    return menuMode === 'overlap' || temporaryMenuOpened;
  }

  navigationChanged = (event:any) => {
    const path = event.itemData.path;
    const pointerEvent = event.event;

    if (path && this.state.menuOpened) {
      if (event.node.selected) {
        pointerEvent.preventDefault();
      } else {
        this.props.history.push(path);
      }

      if (this.hideMenuAfterNavigation) {
        this.setState({
          menuOpened: false,
          temporaryMenuOpened: false
        });
        pointerEvent.stopPropagation();
      }
    } else {
      pointerEvent.preventDefault();
    }
  };

  navigationClick = () => {
    if (!this.state.menuOpened) {
      this.setState({
        temporaryMenuOpened: true,
        menuOpened: true
      })
    }
  };

}

export default withRouter(SideNavInnerToolbar);
