import React from "react";
import {Redirect, Route, Switch} from "react-router";
import {navigation} from "../../app-navigation";
import appInfo from "../../app-info";
import routes from "../../app-routes";
import {SideNavOuterToolbar as  SideNavBarLayout} from "../index";
import {Footer} from "../../components";

export class WorkplaceLayout extends React.Component { //<IWorkplaceLayoutProps,IWorkplaceLayoutState> {

	render() {
		return(
			// @ts-ignore
			<SideNavBarLayout
				menuItems={navigation}
				title={appInfo.title}
				userProfile={this.props.userProfile}
				{...this.props}>
				<Switch>
					{routes.map(item => (
						<Route
							exact
							key={item.path}
							path={item.path}
							component={item.component}
						/>
					))}
					<Redirect to={'/home'} />
				</Switch>
				<Footer>
					Copyright © 2019 Icvr Inc.
					<br />
					All trademarks or registered trademarks are property of their
					respective owners.
				</Footer>
			</SideNavBarLayout>
		);
	}
}