import React from 'react';
import TextBox from 'devextreme-react/text-box';
import ValidationGroup from 'devextreme-react/validation-group';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import Button from 'devextreme-react/button';
import CheckBox from 'devextreme-react/check-box';
import './login-form.scss';
import appInfo from '../../app-info';
import SelectBox from "devextreme-react/select-box";
import ApiClusterService, {IClusterData} from "../../services/clusters/ApiClusterService";

interface ILoginFormsState {
  login: string;
  password: string;
  clusters: IClusterData[];
  cluster: string | undefined;
}

interface IHelloFormProps {
  onLoginClick(login:string, password:string, clusterId:string): void;
}

export default class LoginForm extends React.Component<IHelloFormProps,ILoginFormsState> {

  constructor(props: IHelloFormProps) {
    super(props);

    this.state = {
      login: '',
      password: '',
      clusters: [],
      cluster: undefined
    };
  }

  async componentDidMount() {
      let clusters = await ApiClusterService.getClusters();
      this.setState({clusters: clusters});
  }

  loginChanged = (e: any) => this.setState({login: e.value});
  passwordChanged = (e: any) => this.setState({password: e.value});
  clusterChanged = (e: any) => {
      console.log('clusterChanged', e);
      this.setState({cluster: e.value});
  };

  onLoginClick = (args: any) => {
    if (!args.validationGroup.validate().isValid) {
      return;
    }

    const { login, password, cluster } = this.state;
    console.log('onLoginClick',login, password, cluster);
    if (cluster) {
        this.props.onLoginClick(login, password, cluster);
    }

    args.validationGroup.reset();
  };


  render() {
    let {login, password} = this.state;
    let clusterId = null;
    if (this.state.clusters.length > 0) {
        clusterId = this.state.clusters[0].id;
    }

    return (
        <ValidationGroup>
          <div className={'login-header'}>
            <div className={'title'}>{appInfo.title}</div>
            <img src={'/logo.png'} alt={'logo'}/>
          </div>
          <div className={'dx-field'}>
            <TextBox
                value={login}
                onValueChanged={this.loginChanged}
                placeholder={'Login'}
                width={'100%'}
            >
              <Validator>
                <RequiredRule message={'Login is required'}/>
              </Validator>
            </TextBox>
          </div>
          <div className={'dx-field'}>
            <TextBox
                mode={'password'}
                value={password}
                onValueChanged={this.passwordChanged}
                placeholder={'Password'}
                width={'100%'}
            >
              <Validator>
                <RequiredRule message={'Password is required'}/>
              </Validator>
            </TextBox>
          </div>
            <div className={'dx-field'}>
                <SelectBox
                    items= { this.state.clusters }
                    displayExpr="name"
                    valueExpr="id"
                    onValueChanged={this.clusterChanged}
                    placeholder={'Choose cluster'}
                    defaultValue={clusterId}
                    width={'100%'}
                >
                    <Validator>
                        <RequiredRule message={'Select cluster!'}/>
                    </Validator>
                </SelectBox>
            </div>
          <div className={'dx-field'}>
            <CheckBox defaultValue={false} text={'Remember me'}/>
          </div>
          <div className={'dx-field'}>
            <Button
                type={'default'}
                text={'Login'}
                onClick={this.onLoginClick}
                width={'100%'}
            />
          </div>
        </ValidationGroup>
    );
  }

}
