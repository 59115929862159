import ApiServerRequest from "./api_requests";

export interface IGameData {
	id: string
	created: Date
	version: string
	activeProfiles: string[]
}

export interface IFullGameData {
	id: string
	created: Date
	version: string
	isDefault: boolean
	document: any
}

class GamedataService {

	async getDocuments(): Promise<IGameData[]> {
		return ApiServerRequest.getRequest<IGameData[]>("/GameData/GetGameDataList");
	}

	async startImportGamedata(): Promise<boolean> {
		return ApiServerRequest.postRequest<boolean>("/GameData/Import", {});
	}

	async downloadDocument(version:string):Promise<IFullGameData> {
		return ApiServerRequest.postRequest<IFullGameData>("/GameData/Download", {version: version});
	}

	async setDefaultGamedata(version:string): Promise<boolean> {
		return ApiServerRequest.postRequest<boolean>("/GameData/SetCurrentVersion", {version: version});
	}

}

export default new GamedataService()