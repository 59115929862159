import React from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import UserPanel, { UserPanelMenuMode} from '../user-panel/user-panel';
import './headerPanel.scss';
import { Template } from 'devextreme-react/core/template';
import AuthenticationService, {UserProfile} from "../../services/authenticationService";
import DevExpress from "devextreme/bundles/dx.all";

interface IHeaderPanelProps {
    menuToggleEnabled: boolean
    title: string
    toggleMenu: (event:DevExpress.dxEvent) => void
    userMenuItems: any
    userProfile: UserProfile
}

export default class HeaderPanel extends React.Component<IHeaderPanelProps,{serverProfile:string}> {

    constructor(props: IHeaderPanelProps) {
        super(props);
        this.state = {
            serverProfile: AuthenticationService.getProfile().profileName
        };
    }

    render() {
        return (<header className={'header-component'}>
                <Toolbar className={'header-toolbar'}>
                    <Item
                        visible={this.props.menuToggleEnabled}
                        location={'before'}
                        widget={'dxButton'}
                        cssClass={'menu-button'}
                        options={{
                            icon: 'menu',
                            stylingMode: 'text',
                            onClick: this.props.toggleMenu
                        }}
                    />
                    <Item
                        location={'before'}
                        cssClass={'header-title'}
                        text={this.props.title}
                        visible={!!this.props.title}
                    />
                    <Item location={'after'}>
                        <p>PROFILE:<b>{this.state.serverProfile}</b></p>
                    </Item>
                    <Item
                        location={'after'}
                        locateInMenu={'auto'}
                        menuItemTemplate={'userPanelTemplate'}
                    >
                        <Button
                            className={'user-button authorization'}
                            width={170}
                            height={'100%'}
                            stylingMode={'text'}
                        >
                            <UserPanel menuItems={this.props.userMenuItems}
                                       menuMode={UserPanelMenuMode.Context}
                                       userProfile={this.props.userProfile}
                            />
                        </Button>
                    </Item>
                    <Template name={'userPanelTemplate'}>
                        <UserPanel menuItems={this.props.userMenuItems}
                                   menuMode={UserPanelMenuMode.List}
                                   userProfile={this.props.userProfile}
                        />
                    </Template>
                </Toolbar>
            </header>
        );
    }
}

